<template>
  <div class="popup-box">
    <div class="overlay" id="overlay" @click="closePopup()"></div>
    <md-card
      class="detalhes-box"
      :style="{ top: varTop + 'px' }"
      ref="detalhesBox"
    >
      <i
        class="fa fa-times icon-close"
        aria-hidden="true"
        @click="closePopup()"
      ></i>

      <div class="detalhes-nome">
        {{ produto.Nome }}
      </div>
      <div class="desc">
        {{ produto.Descricao }}
      </div>
      <div class="tags">
        <div
          class="tag"
          :class="tagSelecionada(tag)"
          v-for="tag in produto.Tags"
          :key="tag"
        >
          {{ tag }}
        </div>
      </div>
      <div class="atr">
        <div class="header-back">O produto "{{ produto.Nome }}" contempla:</div>
        <div class="corpo-back">
          <div v-for="retorno in produto.Retornos" :key="retorno">
            <ul>
              <li>
                {{ retorno }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
export default {
  name: "CardDetalhesProdutos",

  data() {
    return {
      showOverlay: false,
      showPopup: false,
      varTop: 0,
    };
  },

  props: {
    produto: Object,
  },

  methods: {
    closePopup() {
      this.$emit("abreFechaDetalhes");
    },

    tagSelecionada() {
      this.$emit("tagSelecionada");
    },

    handleScroll() {
      this.varTop = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: -200px;
  left: -200px;
  width: 100vw;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
}

.detalhes-box {
  width: 55vw;
  max-width: 880px;
  max-height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);

  // transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: var(--accent);
  border-radius: 20px;
  // border: 1px solid var(--accent-60);
  // border: 1px solid white;
  color: white;
  padding: 20px;
  z-index: 100;
  .detalhes-nome {
    font-weight: 700;
    // color: var(--accent-60);
    color: white;
    font-size: 1rem;
    border-bottom: 2px solid white;
    // border-bottom: 2px solid var(--accent-60);
    line-height: 1.2rem;
    width: 90%;
    cursor: default;
  }
  .desc {
    font-size: 14px;
    font-weight: 300;
    margin: 2px 0 0 5px;
  }

  .tags {
    margin: 10px 0 0 0;
    padding-left: 10px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    width: 90%;
    cursor: default;
  }
  .tag {
    font-size: 0.7rem;
    // border: 1px solid var(--mark);
    border: 1px solid #4356a5;
    padding: 0 5px;
    border-radius: 10px;
    // color: var(--mark);
    color: #4356a5;

    display: flex;
    align-items: center;
  }

  .header-back {
    padding: 0 10px;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .corpo-back {
    min-height: 80px;
    font-size: 0.8rem;
    overflow-y: auto;
    line-height: 10px;
    cursor: default;
  }

  .icon-close {
    position: absolute;
    right: 20px;
    font-size: 20px;
    // color: #86878f;
    color: white;
    cursor: pointer;
  }
}

// .close {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   cursor: pointer;
//   font-size: 20px;
//   color: #333;
// }
</style>
