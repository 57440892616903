<template>
  <div class="list" :class="{ clicado: selecionado }">
    <div class="informacao" :class="{ pesquisado: resultadoPesquisa }">
      <div class="nome" @click="abreFechaDetalhes()">
        <div v-html="highlight(produto.Nome)"></div>
      </div>
      <div class="linha"></div>

      <div class="descricao--shrink">
        <div v-html="highlight(produto.Descricao)"></div>
      </div>
    </div>
    <span class="fav-box">
      <checkCustom
        @selected="selectCard()"
        :produto="produto"
        ref="checkCustomRef"
      />
      <span
        class="fav -not"
        v-if="!produtosPreferidos.includes(produto.Box)"
        @click="
          trocarFavorito(produto);
          $event.stopPropagation();
        "
      >
        <img src="/img/selecao-produtos/estrela-n.png" alt="" class="fav-img" />
      </span>
      <span
        class="fav"
        v-else
        @click="
          trocarFavorito(produto);
          $event.stopPropagation();
        "
      >
        <img
          src="/img/selecao-produtos/estrela-preenchida.png"
          alt=""
          class="fav-img"
        />
      </span>
    </span>
    <CardDetalhesProdutos
      v-if="abreDetalhes"
      :produto="produto"
      @abreFechaDetalhes="abreFechaDetalhes"
      @tagSelecionada="tagSelecionada"
    />
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { mapState } from "pinia";
import CardDetalhesProdutos from "./CardDetalhesProdutos";

export default {
  name: "CardProdutoLista",

  props: {
    produto: Object,
    search: String,
    tags: Array,
  },
  components: {
    checkCustom: () => import("@/components/Visuais/checkCustom.vue"),
    CardDetalhesProdutos,
  },
  data() {
    return {
      abreDetalhes: false,
    };
  },

  methods: {
    tagSelecionada(tag) {
      if (this.tags.includes(tag)) {
        return "tagSelecionada";
      }
      return "";
    },

    abreFechaDetalhes() {
      if (this.abreDetalhes) {
        this.abreDetalhes = false;
      } else {
        this.abreDetalhes = true;
      }
      this.$emit("ativaDetalhes", this.abreDetalhes);
    },

    highlight(texto) {
      if (!this.search) {
        return texto;
      }

      return texto.replace(new RegExp(this.search, "gi"), (match) => {
        return '<span class="highlightText">' + match + "</span>";
      });
    },

    selectCard() {
      this.$emit("cardSelected", this.produto);
    },

    trocarFavorito(produto) {
      this.$emit("trocarFavorito", produto);
    },
  },

  mounted() {},

  computed: {
    selecionado() {
      if (this.produto.Selecionado) {
        return true;
      }
      return false;
    },

    resultadoPesquisa() {
      if (this.produto.Pesquisado) {
        return true;
      }
      return false;
    },

    ...mapState(userPreferenceStore, {
      produtosPreferidos: "produtosPreferidos",
    }),
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.list {
  width: 100%;
  background: transparent;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  padding: 5px;
}

.informacao {
  padding: 5px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $outbox-medium-blue;
  margin: 0 10px;
  color: #86878f;

  &.pesquisado {
    border: 2px solid #61c29c;
    border-radius: 20px;
    padding: 10px;
    position: relative;
    right: 10px;
    background-color: #61c29c20;
    font-weight: 600;
  }
}
.nome {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  width: 170px;
  cursor: pointer;

  &:hover {
    color: #61c29c;
  }
}
.fav-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fav {
  cursor: pointer;
  margin: 0 5px 0 10px;
  display: flex;
  width: 20px;
  height: 50px;
  align-items: center;

  .fav-img {
    height: 20px;
    width: 20px;
  }
}

.linha {
  width: 1px;
  height: 30px;
  background-color: #86878f;
}
.descricao--shrink {
  font-weight: 400;
  font-size: 14px;
  width: 60%;
  margin: 0 20px;
  cursor: default;
}

@media (max-width: 1024px) {
  .informacao {
    height: fit-content;
  }
}
</style>
