<template>
  <div class="list" :class="{ clicado: selecionado }">
    <div class="imagem-box">
      <img class="imagem" :src="produto.imagem" alt="" />
    </div>
    <div class="corpo" >
      <div class="nome-box" @click="abreDetalhes = true">
        <div v-html="highlight(produto.titulo)"></div>
      </div>
      <div class="linha"></div>
      <div class="descricao--shrink">
        <div v-html="highlight(produto.descricao)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { mapState } from "pinia";

export default {
  name: "CardProdutoLista",
  props: {
    produto: Object,
    search: String,
    tags: Array,
    produtosSuperBox: Array,
  },
  components: {
  },
  data() {
    return {
      abreDetalhes: false,
    };
  },

  methods: {
    tagSelecionada(tag) {
      if (this.tags.includes(tag)) {
        return "tagSelecionada";
      }
      return "";
    },
    highlight(texto) {
      if (!this.search) {
        return texto;
      }

      return texto.replace(new RegExp(this.search, "gi"), (match) => {
        return '<span class="highlightText">' + match + "</span>";
      });
    },
  },

  mounted() {},
  
  computed: {
    selecionado() {
      if (this.produto.Selecionado) {
        return true;
      }
      return false;
    },

    resultadoPesquisa() {
      const produtoBox = this.produto.box;

      const produtoEncontrado = this.produtosSuperBox.find(
        (produto) => produto.Box === produtoBox
      );

      if (produtoEncontrado) {
        return produtoEncontrado.Pesquisado;
      } else {
        return false;
      }

      
    },

    ...mapState(userPreferenceStore, {
      produtosPreferidos: "produtosPreferidos",
    }),
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.list {
  width: 100%;
  background: transparent;
  display: flex;
  margin: 0 0 5px 0;
  align-items: flex-start;
  border-radius: 10px;
  padding: 0 5px;
}

.corpo {
  display: flex;
  border-bottom: solid 1px #1da0f2;
  width: 80%;
  height: 45px;
  display: flex;
  align-items: center;
  color: #86878f;
  margin: 0 0 0 30px;

  &.pesquisado {
    border: 2px solid #61c29c;
    border-radius: 20px;
    padding: 10px;
    position: relative;
    right: 10px;
    background-color: #61c29c20;
    font-weight: 600;
  }
}

.nome-box {
  color: #86878f;

  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  min-width: 150px;
  text-transform: uppercase;
  cursor: default;

  // &:hover {
  //   color: #61c29c;
  // }
}

.linha {
  width: 1px;
  height: 30px;
  background-color: #86878f;
}
.imagem-box {
  height: 45px;
}
.imagem {
  height: 45px;
}

.descricao--shrink {
  padding: 0 0 0 50px;
  cursor: default;
  font-size: 14px;
}
.clicado {
  border: 2px solid var(--accent-60);
}
.tags {
  margin-top: 10px;
  padding-left: 10px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 90%;
}
.tag {
  font-size: 0.7rem;
  border: 1px solid var(--mark);
  padding: 0 5px;
  border-radius: 10px;
  color: var(--mark);

  display: flex;
  align-items: center;
}

.tagSelecionada {
  background: var(--mark);
  color: white;
}

.ver-mais {
  width: 5%;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  cursor: pointer;

  .rotate {
    transform: rotate(180deg);
  }
}
</style>
