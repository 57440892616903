<template>
  <div class="produtos">
    <ConfirmaSelecao
      v-if="popUpOn"
      :produtos="produtosSelecionados"
      :search="search"
      :texto="texto"
      :values="values"
      @fechaModal="fechaPopUp"
      @substituiProdutos="emiteSubstitui"
      @apenasVisiveis="submit(produtosFiltrados)"
      @seleciona-card="selecaoProduto"
    />
    <span class="tipo-pessoa-box">
      <span class="material-icons icone">{{ iconeTipoPessoa }}</span>
      {{ opcao_inicial }}
    </span>

    <div class="linha1">
      <div class="disclaimer">Selecione os produtos que deseja consultar</div>
    </div>
    <div class="down">
      <span class="searchBox">
        <button
          class="icon-button"
          id="search-button"
          @click="liberaPesquisa = !liberaPesquisa"
        >
          <span class="material-symbols-outlined icon-search">search</span>
        </button>
        <b-tooltip
          :target="'search-button'"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          Pesquisar por palavras-chave
        </b-tooltip>
        <md-field
          v-if="liberaPesquisa"
          class="searchInput md-layout-item md-size-45 md-medium-size-50 md-xsmall-size-100"
        >
          <label for="pesquisaProdutos"> Pesquisar </label>
          <md-input id="pesquisaProdutos" v-model="pesquisaProd"></md-input>
          <!-- <span class="material-symbols-outlined icon-search">search</span> -->
        </md-field>
      </span>
      <div class="footer">
        <md-card-actions class="btn-right">
          <md-button
            data-html2canvas-ignore
            native-type="submit"
            class="md-primary"
            @click="passoAnterior()"
            >Anterior
          </md-button>
          <md-button
            data-html2canvas-ignore
            native-type="submit"
            :disabled="produtosSelecionadosLength == 0"
            class="md-primary"
            @click="decideProxPasso()"
            >Próxima
          </md-button>
        </md-card-actions>
      </div>
    </div>
    <div class="cards" :class="{ 'sem-margem': liberaPesquisa }">
      <div class="cardBox consultas">
        <div class="header consultas">
          <div class="headerTitle">
            <img :src="iconeConsultas" alt="" class="icone-produtos" />
            <div class="nome consultas">
              <div v-html="highlight(titleConsultas)"></div>
            </div>
            <div class="seleciona_todos">
              Selecionar todas
              <div class="checkbox">
                <div>
                  <input
                    class="input"
                    :checked="checkTodasConsultas"
                    type="checkbox"
                    @change="selecionaTodasConsultas()"
                  />
                </div>
              </div>
              <!-- <checkCustom @selected="selecionaTodasConsultas($event)" /> -->
            </div>
            <img
              src="/img/selecao-produtos/Seta-cinza.png"
              class="arrow-icon cinza"
              :class="{ aberta: openConsultasOutbox }"
              @click="openConsultasOutbox = !openConsultasOutbox"
            />
          </div>
          <div class="text-desc">{{ descConsultas }}</div>
        </div>

        <div
          class="dropdown"
          :class="{ 'dropdown--open': openConsultasOutbox }"
          v-if="openConsultasOutbox"
        >
          <CardProdutoLista
            v-for="produto in produtosPrateleira"
            :key="produto.Nome + ' prateleira'"
            :produto="produto"
            :search="pesquisaProd"
            :tags="values"
            @cardSelected="selecionado"
            @trocarFavorito="trocarFavorito"
            @ativaDetalhes="ativaDetalhes"
          />
        </div>
      </div>
      <div class="cardBox consultas" v-if="produtosCustomizados.length > 0">
        <div class="header consultas">
          <div class="headerTitle">
            <img :src="iconeCustomizadas" alt="" class="icone-produtos" />
            <div class="nome consultas">
              <div v-html="highlight(titleCostomizadas)"></div>
            </div>
            <div class="seleciona_todos">
              Selecionar todas
              <div class="checkbox">
                <div>
                  <input
                    class="input"
                    :checked="checkTodasCustomizadas"
                    type="checkbox"
                    @change="selecionaTodasCustomiz()"
                  />
                </div>
              </div>
              <!-- <checkCustom @selected="selecionaTodasCustomiz($event)" /> -->
            </div>
            <img
              src="/img/selecao-produtos/Seta-cinza.png"
              class="arrow-icon cinza"
              :class="{ aberta: openCustomizadas }"
              @click="openCustomizadas = !openCustomizadas"
            />
          </div>
          <div class="text-desc">{{ descCustomizadas }}</div>
        </div>

        <div
          class="dropdown"
          :class="{ 'dropdown--open': openCustomizadas }"
          v-if="openCustomizadas"
        >
          <CardProdutoLista
            v-for="produto in produtosCustomizados"
            :key="produto.Nome + ' customizado'"
            :produto="produto"
            :search="pesquisaProd"
            :tags="values"
            @cardSelected="selecionado"
            @trocarFavorito="trocarFavorito"
          />
        </div>
      </div>
      <div class="nao_encontrado" v-if="produtosFiltrados.length == 0">
        <span class="material-icons-outlined sad">sentiment_dissatisfied</span>
        Desculpe, não há produtos com o filtro buscado
      </div>
    </div>
  </div>
</template>

<script>
import CardProdutoLista from "./CardProdutoLista";
import ConfirmaSelecao from "@/components/Visuais/ConfirmaSelecao";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";

import { Tags } from "@/helpers/";

export default {
  name: "Produtos",
  props: {
    produtos: Array,
    opcao_inicial: String,
  },
  components: {
    // checkCustom: () => import("@/components/Visuais/checkCustom.vue"),
    CardProdutoLista,
    ConfirmaSelecao,
  },
  data() {
    return {
      contadorSelecionado: false,
      contador: 0,
      search: "",
      filtro: "texto",
      options: [],
      values: [],
      texto: true,
      popUpOn: false,
      titleConsultas: "Produtos Outbox",
      iconeConsultas: "/img/selecao-produtos/icone-produtos.png",
      titleCostomizadas: "Consultas Customizadas",
      iconeCustomizadas: "/img/selecao-produtos/icone-customizadas.png",
      descConsultas:
        "Ferramentas decisórias construídas para uso 'plug-&-play', com aplicações amplas em seus respectivos segmentos, fácil integração e direcionadas para ampliação da capacidade de gerencialmente de risco, ganho de escala e otimizações estratégicas.",
      descCustomizadas:
        "Exclusivas para seu perfil de carteira, estes produtos são construídos de maneira personalizada para traduzir a individualidade do segmento tratado e amplificar sua capacidade de tomada de decisões baseadas em dados e analytics.",
      openConsultasOutbox: true,
      openCustomizadas: true,
      pesquisaProd: "",
      liberaPesquisa: false,
      abreDetalhes: false,
      checkTodasConsultas: false,
      checkTodasCustomizadas: false,
    };
  },

  methods: {
    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),

    ativaDetalhes(ativa) {
      this.abreDetalhes = ativa;
    },

    atualizarPesquisados(valorPesquisa) {
      if (valorPesquisa === "") {
        this.produtos.forEach((produto) => {
          produto.Pesquisado = false;
        });
      } else {
        this.produtos.forEach((produto) => {
          const contemNoBox = produto.Box.toLowerCase().includes(
            valorPesquisa.toLowerCase()
          );
          const contemNoNome = produto.Nome.toLowerCase().includes(
            valorPesquisa.toLowerCase()
          );
          const contemNaDescricao = produto.Descricao.toLowerCase().includes(
            valorPesquisa.toLowerCase()
          );

          const contemNasTags = produto.Tags.some((tag) =>
            tag.toLowerCase().includes(valorPesquisa.toLowerCase())
          );

          produto.Pesquisado =
            contemNoBox || contemNoNome || contemNaDescricao || contemNasTags;
        });
      }
    },

    selecionaTodasConsultas() {
      this.checkTodasConsultas = !this.checkTodasConsultas;
      if (
        this.produtosSelecionadosLengthPrateleira <
          this.produtosPrateleira.length &&
        this.checkTodasConsultas
      ) {
        this.selecionaTodosPrateleira(true);
      } else {
        this.selecionaTodosPrateleira(false);
      }

      if (
        this.produtosSelecionadosLengthPrateleira ==
        this.produtosPrateleira.length
      ) {
        return true;
      }
    },

    selecionaTodasCustomiz() {
      this.checkTodasCustomizadas = !this.checkTodasCustomizadas;

      if (
        this.produtosSelecionadosLengthCustomizado <
          this.produtosCustomizados.length &&
        this.checkTodasCustomizadas
      ) {
        this.selecionaTodosCustomizado(true);
      } else {
        this.selecionaTodosCustomizado(false);
      }
    },

    emiteSubstitui(produtos_) {
      this.$emit("substituiProdutos", produtos_);
    },
    selecaoProduto(e) {
      e.Selecionado = !e.Selecionado;
    },

    decideProxPasso() {
      if (
        this.produtosSelecionadosFiltros < this.produtosSelecionadosLength &&
        this.produtosSelecionadosFiltros > 0
      ) {
        this.popUpOn = true;
        this.analytics("PopUp aberto", "Avisos", "PopUp aberto");
      } else {
        if (parseInt(this.$route.fullPath.slice(-1)) == 1) {
          this.$router.push("/consultas/box/2");
        } else {
          this.$router.push("/consultas/box/5");
        }
      }
    },

    passoAnterior() {
      this.$router.push("/consultas/box/0");
    },

    fechaPopUp() {
      this.popUpOn = false;
    },
    changeProdutos(e) {
      this.search = e;
    },
    changeTags(e) {
      this.values = e;
    },

    highlight(texto) {
      if (!this.pesquisaProd) {
        return texto;
      }

      return texto.replace(new RegExp(this.pesquisaProd, "gi"), (match) => {
        return '<span class="highlightText">' + match + "</span>";
      });
    },

    trocarFavorito(produto) {
      if (this.produtosPreferidos.includes(produto.Box)) {
        this.produtosPreferidos = this.produtosPreferidos.filter(
          (produtoPref) => produto.Box != produtoPref
        );
      } else {
        this.produtosPreferidos.push(produto.Box);
        produto.Favorito = true;
      }

      //Chama api trocando tudo.
      this.substituiPreferencia(
        "PortalOutbox_produtosFavoritos",
        this.produtosPreferidos.join(";")
      );
    },
    selecionado(e) {
      if (e.Selecionado) {
        this.contador++;
        this.contadorSelecionado = true;
      } else {
        this.contador--;
        if (this.contador == 0) {
          this.contadorSelecionado = false;
        }
      }
    },
    verificaTags(produto, values) {
      if (values.length > 0) {
        let x = false;
        values.forEach((value) => {
          produto.Tags.forEach((tag) => {
            if (tag === value) {
              x = true;
            }
          });
        });
        return x;
      } else {
        return true;
      }
    },

    selecionaTodosPrateleira(e) {
      if (e) {
        this.produtosFiltrados.forEach((produto) => {
          if (!produto.Selecionado && produto.Classificacao == "Prateleira") {
            this.$set(produto, "Selecionado", true);
            this.selecionado(produto);
          }
        });
      } else {
        this.produtosFiltrados.forEach((produto) => {
          if (produto.Selecionado && produto.Classificacao == "Prateleira") {
            this.$set(produto, "Selecionado", false);
            this.selecionado(produto);
          }
        });
      }
    },
    selecionaTodosCustomizado(e) {
      if (e) {
        this.produtosFiltrados.forEach((produto) => {
          if (!produto.Selecionado && produto.Classificacao == "Customizado") {
            this.$set(produto, "Selecionado", true);
            this.selecionado(produto);
          }
        });
      } else {
        this.produtosFiltrados.forEach((produto) => {
          if (produto.Selecionado && produto.Classificacao == "Customizado") {
            this.$set(produto, "Selecionado", false);
            this.selecionado(produto);
          }
        });
      }
    },

    verificarSelect() {
      if (
        this.produtosSelecionadosLengthPrateleira ===
        this.produtosPrateleira.length
      ) {
        this.checkTodasConsultas = true;
      } else {
        this.checkTodasConsultas = false;
      }
      if (
        this.produtosSelecionadosLengthCustomizado ===
        this.produtosCustomizados.length
      ) {
        this.checkTodasCustomizadas = true;
      } else {
        this.checkTodasCustomizadas = false;
      }
    },
  },

  watch: {
    texto: function (newVal, oldVal) {
      if (newVal) {
        this.analytics(
          `Filtro por texto selecionado`,
          "Utilitários",
          "Mudou o filtro para texto"
        );
      } else {
        this.analytics(
          `Filtro por tag selecionado`,
          "Utilitários",
          "Mudou o filtro para tags"
        );
      }
    },

    produtosSelecionadosLengthPrateleira: "verificarSelect",
    "produtosPrateleira.length": "verificarSelect",
    produtosSelecionadosLengthCustomizado: "verificarSelect",
    "produtosCustomizados.length": "verificarSelect",

    liberaPesquisa() {
      if (!this.liberaPesquisa) {
        this.pesquisaProd = "";
      }
    },

    pesquisaProd(newVal) {
      this.atualizarPesquisados(newVal);
    },
  },

  async mounted() {
    this.pesquisaProd = "";
    this.atualizarPesquisados(this.pesquisaProd);

    const preferenciasFavoritos = await this.syncPreferencias();
    if (preferenciasFavoritos === undefined) {
      await this.createPreferencia("PortalOutbox_produtosFavoritos");
    } else {
      const valor = preferenciasFavoritos.Valor;
      if (valor != "") {
        this.produtosPreferidos = valor.split(";");
      }
    }

    if (
      this.produtosSelecionadosLengthPrateleira ==
      this.produtosPrateleira.length
    ) {
      this.checkTodasConsultas = true;
    }
    if (
      this.produtosSelecionadosLengthCustomizado ==
      this.produtosCustomizados.length
    ) {
      this.checkTodasCustomizadas = true;
    }
  },

  computed: {
    ...mapWritableState(userPreferenceStore, {
      produtosPreferidos: "produtosPreferidos",
    }),

    produtosSelecionadosFiltros() {
      let x = this.produtosFiltrados.filter((produto) => {
        return produto.Selecionado;
      });
      return x.length;
    },

    produtosSelecionadosLength() {
      return (
        this.produtosSelecionadosLengthPrateleira +
        this.produtosSelecionadosLengthCustomizado
      );
    },

    produtosSelecionadosLengthPrateleira() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado && produto.Classificacao === "Prateleira";
      }).length;
    },

    produtosSelecionadosLengthCustomizado() {
      return this.produtos.filter((produto) => {
        return produto.Selecionado && produto.Classificacao === "Customizado";
      }).length;
    },

    produtosSelecionados() {
      let x = this.produtos.filter((produto) => {
        return produto.Selecionado;
      });
      return x;
    },

    produtosFiltrados() {
      let produtos = [];
      if (this.texto) {
        produtos = this.produtos.filter((produto) => {
          if (
            produto.Nome.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            produto.Descricao.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1
          ) {
            return true;
          }
        });
      } else {
        produtos = this.produtos.filter((produto) => {
          return this.verificaTags(produto, this.values);
        });
      }
      return produtos;
    },

    produtosPrateleira() {
      const produtosFiltradosTemp = this.produtosFiltrados.filter(
        (produto) => produto.Classificacao === "Prateleira"
      );

      this.produtosFiltrados.forEach((produto) => {
        if (this.produtosPreferidos.includes(produto.Box)) {
          produto.Favorito = true;
        } else {
          produto.Favorito = false;
        }
      });

      // Ordenar os produtos favoritos
      produtosFiltradosTemp.sort((a, b) => {
        if (a.Favorito === b.Favorito) {
          return 0;
        }
        return a.Favorito ? -1 : 1;
      });

      return produtosFiltradosTemp;
    },
    produtosCustomizados() {
      return this.produtosFiltrados.filter(
        (produto) => produto.Classificacao === "Customizado"
      );
    },

    iconeTipoPessoa() {
      if (this.opcao_inicial == "Pessoa Física") {
        return "face";
      } else if (this.opcao_inicial == "Pessoa Jurídica") {
        return "business";
      } else if (this.opcao_inicial == "Geolocalização") {
        return "location_on";
      } else {
        return "";
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.linha1 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4356a5;
  border-radius: 20px;
  height: 60px;
  background-image: url(/img/selecao-produtos/barra.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  width: 75%;
  max-width: 900px;
}

.nao_encontrado {
  margin: 30px 0;
  grid-column: 1/4;
  font-size: 1.5rem;
  font-weight: 300;

  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.sad {
  font-size: 3rem;
}
.produtos {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  max-width: 100%;
}
.tipo-pessoa-box {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;

  position: absolute;
  right: 13%;
  top: -35px;

  @include mediaQuery(large) {
    right: 22%;
  }
  .icone {
    position: absolute;
    top: -65px;
    font-size: 70px;
    opacity: 0.2;
  }
}
.disclaimer {
  padding: 0 20px;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  margin: 0 0 5px 0;
}

.searchBox {
  display: flex;
  width: 75%;
  justify-content: flex-start;
  max-width: 900px;
  align-items: center;
  padding: 0 5px;
}

.searchInput {
  margin: 10px 0px 0 40px;
  padding: 5px 0;
  border-radius: 20%;
  max-width: 270px !important;
  min-width: 270px !important;
}

.icon-search {
  font-size: 18px;
  padding: 0;
  height: 18px;
  cursor: default;
  cursor: pointer;
}
.icon-button {
  margin: 5px 0 0 0;
  padding: 5px;
  border-radius: 5px;
  height: 28px;
  cursor: pointer;
  border: none;
  position: absolute;
  z-index: 2;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  &:active {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.seleciona_todos {
  display: flex;
  gap: 10px;
  width: 30%;
  color: #68686b;
}
.cards {
  width: 75%;
  max-width: 900px;
  margin: 0px 0 10px 0;
  padding: 0 5px;
  gap: 10px;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  height: fit-content;

  &.sem-margem {
    margin: 0px;
  }
}

.cardBox {
  width: 100%;
  border-radius: 20px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.consultas {
    border: 1px solid $outbox-medium-blue;
    background-color: white;
  }
}

.header {
  width: 100%;
  // height: 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
  margin: 0;
  border-radius: 20px;
  cursor: pointer;
  z-index: 10;

  &.consultas {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 20px;
    gap: 0px;
  }
}

.header-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;

  &.tit {
    flex: 1;
  }

  &.linha {
    background-color: #4356a5;
    height: 120px;
    width: 2px;

    &.selecionado {
      background-color: white;
    }
  }

  &.selecao {
    align-items: center;
    margin: 0 40px 0 20px;
  }
}

.img-logo-sb {
  height: 150px;
}

.select-icon {
  height: 50px;
  margin: 30px 0 20px 0;
  position: relative;
}

.arrow-icon {
  height: 40px;
  width: 10px;
  transform: rotate(90deg);
  position: relative;

  &.aberta {
    transform: rotate(270deg);
  }
  &.cinza {
    height: 30px;
    bottom: 20px;
  }
}
.icon {
  height: 46px;
  font-size: 4rem;
  margin-right: 20px;
}

.headerTitle {
  width: 100%;
  display: flex;
  align-items: center;
  height: 70px;
  cursor: pointer;
}
.nome {
  font-weight: 600;
  font-size: 40px;
  width: 60%;

  &.consultas {
    font-size: 22px;
    color: #86878f;
  }
  &.sup {
    line-height: 60px;
  }
}

.descricao {
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
}

.text-desc {
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
  color: #68686b;
}
.icone-produtos {
  height: 50px;
  margin: 0 10px;
}
.dropdown {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
  height: 0;
  visibility: hidden;
  transition: height 0.3s ease;
  border-radius: 20px;
  position: relative;
  bottom: 30px;

  &--open {
    width: 100%;
    height: fit-content;
    visibility: visible;
    padding-top: 40px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 20px;
  width: 100%;
  padding: 0 0px;
  background-color: transparent;
  .btn-right {
    gap: 10px;
  }
}

.down {
  width: 75%;
  max-width: 890px;
  display: flex;
  position: relative;
  top: 20px;
}

@media (max-width: 1240px) {
  .linha1 {
    min-width: 500px;
  }
  .disclaimer {
    font-size: 1.5rem;
  }

  .header {
    min-width: 500px;
  }
}
@media (max-width: 600px) {
  .produtos {
    overflow-x: scroll;
    align-items: flex-start;
  }
  .headerTitle {
    justify-content: space-between;
  }
  .searchBox {
    width: 100%;
    min-width: 280px;
  }

  .cards {
    width: auto !important;
  }
  .disclaimer {
    min-width: 500px;
  }

  .tipo-pessoa-box {
    right: 0;
    top: -22px;
  }

  .seleciona_todos {
    width: auto;
  }

  .nome {
    width: auto;
  }
}
.disable {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox {
  /* padding: 5px 0; */
  display: flex;
  justify-content: center;
  margin-top: 5px;
  /* background:rgba(255, 255, 0, 0.392); */
}

input[type="checkbox"] {
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  /* color: #fff; */
  width: 18px;
  height: 18px;
  border: 0.15em solid #1da0f2;
  border-radius: 5px;
  transform: translateY(-0.075em);
  cursor: pointer;
  display: grid;
  place-content: center;
}

input:checked {
  background-color: #1da0f2;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>
